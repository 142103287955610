import React, { useCallback, useEffect, useState } from 'react';
import IMenu from '../../interfaces/menu';
import MenuItemsLoader from '../loading/menuLoader';
import { useTranslation } from 'react-i18next';
import { MenuItemDto } from '../../api-types';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectActiveUser,
    selectIsGuest,
    selectUserSettings
} from '../../app/userSlice';
import Modal from 'react-modal';
import { extendedModalStyles } from '../../config/modal';
import { MenuCategory } from '../menuCategory';
import {
    changeMenuType,
    removePreparedConstantMenuItems,
    removePreparedMenuItems,
    saveMenuItems,
    selectMenuItems,
    selectMenuType,
    selectPreparedConstantMenuItems,
    selectPreparedMenuItems
} from '../../app/menuSlice';
import { getGroups, getMenu } from '../../api/menu';
import { MenuItemType } from '../../api-types/models/MenuItemDto';
import { selectConfig } from '../../app/configSlice';
import { LoginType } from '../../api-types/models/LoginDto';
import { Prompt } from '../prompt';
import { EditModeWarning } from '../editModeWarning';
import { AddMenuItem } from '../addMenuItem';
import { selectDate } from '../calendar/calendarSlice';
import { format, isPast, isToday } from 'date-fns';
import { isEmpty } from 'lodash';
import { Category } from '../../api-types/models/Category';
import { selectAuthUser } from '../../app/authSlice';
import { getUserGroups } from '../../utils/getUserGroups';
import { NavigationTabs } from '../navigationTabs';
import { DateSelector } from '../dateSelector';

import './index.css';
import { CustomEvent } from '@piwikpro/react-piwik-pro';
import { capitalizeFirstLetter } from 'utils/capitalizeCase';

interface IProps {
    showSuccessToast: boolean;
    setShowSuccessToast(value: boolean): void;
    loginType: string | null;
    date: Date;
}

export const Menu: React.FunctionComponent<IMenu & IProps> = ({
    showSuccessToast,
    setShowSuccessToast,
    loginType,
    date
}: IProps) => {
    const dispatch = useAppDispatch();

    const [time, setTime] = useState<Date>(new Date());
    const [loading, setLoading] = useState(true);
    const [isAddMenuItemModalOpen, setIsAddMenuItemModalOpen] = useState(false);
    const [groups, setGroups] = useState<Category[]>([]);

    const userSettings = useAppSelector(selectUserSettings);
    const isGuest = useAppSelector(selectIsGuest);
    const activeUser = useAppSelector(selectActiveUser);
    const menuItemType = useAppSelector(selectMenuType);
    const menuItems = useAppSelector(selectMenuItems);
    const config = useAppSelector(selectConfig);
    const preparedMenuItems = useAppSelector(selectPreparedMenuItems);
    const selectedDate = useAppSelector(selectDate);
    const user = useAppSelector(selectAuthUser);
    const preparedMenuConstantItems = useAppSelector(
        selectPreparedConstantMenuItems
    );

    const { t } = useTranslation();

    const handleChangeMenuItemType = useCallback((selectedMenuType: string) => {
        dispatch(changeMenuType(selectedMenuType));
    }, []);

    const openAddMenuItemModal = useCallback(() => {
        setIsAddMenuItemModalOpen(true);
    }, []);

    useEffect(() => {
        //cleanup
        setLoading(true);
        config &&
            getMenu(date)
                .then((menuItems) => {
                    dispatch(saveMenuItems(menuItems));
                })
                .catch((error) => console.log(error));
    }, [date, isGuest, config]);

    // Live countdown
    useEffect(() => {
        const interval = setInterval(() => setTime(new Date()), 1000);

        return () => clearInterval(interval);
    }, [time]);

    useEffect(() => {
        if (menuItems) {
            setLoading(false);
        }
    }, [menuItems]);

    useEffect(() => {
        config &&
            getGroups().then((response) => {
                if (!Object.keys(response).includes('error')) {
                    setGroups(response as Category[]);
                }
            });
    }, [config]);

    useEffect(() => {
        CustomEvent.trackEvent(
            'page_view',
            `Menu ${
                loginType === LoginType.BRUNCH ? '(Brunch)' : ''
            } - ${capitalizeFirstLetter(menuItemType.toLowerCase())}`
        );
    }, [menuItemType]);

    if (
        loading ||
        (user &&
            !getUserGroups(user).includes(LoginType.BRUNCH) &&
            !userSettings)
    ) {
        return (
            <div className='loading'>
                <MenuItemsLoader />
            </div>
        );
    }

    let menuItemsCopy = menuItems ?? [];

    if (loginType !== LoginType.BRUNCH && userSettings?.foodFilter) {
        // TODO: Add icecream to Profile page to filter it out
        const filteredTags: string[] = Object.keys(
            userSettings.foodFilter
        ).filter(
            (tag: string) =>
                userSettings.foodFilter[tag] === true ||
                tag === 'SELF_SERVED' ||
                tag === 'ICE_CREAM'
        );

        // Apply filter on menu only if the user is not guest
        if (activeUser === undefined && menuItemType === MenuItemType.KITCHEN) {
            menuItemsCopy = menuItemsCopy.filter((menuItem: MenuItemDto) =>
                menuItem.category
                    ? filteredTags.includes(menuItem.category)
                    : true
            );
        }

        const filteredAllergens: string[] = Object.keys(
            userSettings.allergies
        ).filter(
            (allergen: string) => userSettings.allergies[allergen] === true
        );

        // Apply filter on menu only if the user is not guest
        if (activeUser === undefined) {
            menuItemsCopy = menuItemsCopy.filter((menuItem: MenuItemDto) => {
                return !filteredAllergens.length
                    ? true
                    : menuItem.allergens.every(
                          (allergen: string) =>
                              !filteredAllergens.includes(allergen)
                      );
            });
        }
    }

    if (
        menuItems &&
        !menuItems.length &&
        !getUserGroups(user).includes(LoginType.BRUNCH)
    ) {
        return (
            <div className='no-menu'>
                <div className='content'>
                    <h1>{t('no_menu_title')}</h1>
                    <p>{t('no_menu_description')}</p>
                </div>
                <div className='image'>
                    <img src='/images/no-menu.svg' alt='no-menu' />
                </div>
            </div>
        );
    }

    if (
        !menuItemsCopy.length &&
        !getUserGroups(user).includes(LoginType.BRUNCH)
    ) {
        return (
            <div className='wrong-filter'>
                <div className='content'>
                    <h1>{t('wrong_filter_title')}</h1>
                    <p>{t('wrong_filter_description')}</p>
                </div>
                <div className='image'>
                    <img src='/images/no-menu.svg' alt='no-menu' />
                </div>
            </div>
        );
    }

    const preparedMenuItemsForToday = Object.keys(preparedMenuItems).includes(
        format(selectedDate, 'yyyy-MM-dd')
    )
        ? preparedMenuItems[format(selectedDate, 'yyyy-MM-dd')]
        : [];

    menuItemsCopy =
        [
            ...menuItemsCopy,
            ...preparedMenuItemsForToday,
            ...preparedMenuConstantItems
        ]?.filter((item) => item.costCenter === menuItemType) ?? [];

    return (
        <>
            <NavigationTabs
                activeTab={menuItemType}
                setActiveTab={handleChangeMenuItemType}
                tabs={Object.values(MenuItemType)}
            />
            <div className='menu'>
                <div className='menu-actions'>
                    {getUserGroups(user).includes(LoginType.BRUNCH) &&
                        (!isPast(selectedDate) || isToday(selectedDate)) && (
                            <div className='admin-actions'>
                                <button
                                    className='btn btn-gray'
                                    onClick={openAddMenuItemModal}
                                >
                                    + {t('add_menu')}
                                </button>
                            </div>
                        )}
                </div>

                {showSuccessToast && (
                    <div className='success-toast'>
                        <img
                            className='success-toast-img'
                            src='/images/ico_success.svg'
                            alt='Success'
                        />
                        <span className='menu-closed-text'>
                            {t('rating_success')}
                        </span>
                        <div
                            className='close-toast'
                            onClick={() => setShowSuccessToast(false)}
                        >
                            <img alt='close' src='/images/ico_close.svg' />
                        </div>
                    </div>
                )}

                <div className='menu-groups'>
                    {getUserGroups(user).includes(LoginType.BRUNCH) && (
                        <DateSelector />
                    )}
                    {groups.map((group) => (
                        <MenuCategory
                            key={group.name}
                            category={group.name}
                            menuItems={menuItemsCopy.filter(
                                (menuItems) =>
                                    menuItems.group?.toUpperCase() ===
                                    group.name.toUpperCase()
                            )}
                        />
                    ))}
                </div>
            </div>
            {(!isEmpty(preparedMenuItems) ||
                !isEmpty(preparedMenuConstantItems)) && (
                <EditModeWarning
                    cancelEditMode={() => {
                        dispatch(removePreparedMenuItems());
                        dispatch(removePreparedConstantMenuItems());
                    }}
                />
            )}
            {isAddMenuItemModalOpen && (
                <Modal
                    isOpen={isAddMenuItemModalOpen}
                    style={extendedModalStyles}
                    onRequestClose={() => setIsAddMenuItemModalOpen(false)}
                    ariaHideApp={false}
                >
                    <AddMenuItem
                        closeModal={() => setIsAddMenuItemModalOpen(false)}
                    />
                </Modal>
            )}
            {!isEmpty(preparedMenuItems) && <Prompt isBlocked={true} />}
        </>
    );
};

export default Menu;
